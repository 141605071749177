<template>
  <div>
    <Toast position="top-right" />
    <modal
      name="modal-sem-licenciada"
      :clickToClose="false"
      scrollable
      width="30%"
      height="auto"
    >
      <div class="card">
        <div class="card-header py-4"><h4>Informação do sistema</h4></div>
        <div class="card-body">
          <h6 class="card-title p-0">Sem agente!</h6>
          <p class="card-text mt-2">
            Não foi entrando nenhum agente para sua conta. Para realizar o
            cadastro/edição de um sistema, um agente deve ser criado! <br />
            <strong>Você deseja cadastrar um agente agora?</strong>
          </p>
        </div>
        <div class="card-footer p-2 d-flex justify-content-end">
          <b-button @click="paginaAnterior(1)">Não</b-button>
          <b-button
            @click="redirecionarParaCadastrarAgente"
            class="ml-3"
            variant="success"
            >Sim, cadastrar</b-button
          >
        </div>
      </div>
    </modal>
    <section class="licen-container">
      <b-form>
        <!-- <a class="licen-botao-voltar" @click="paginaAnterior(1)"
          ><b-icon-arrow-left-circle font-scale="2.5" style="color: 808080" />
        </a> -->
        <!-- <div class="licen-header mb-6">
          <div class="back-arrow-squared" @click="paginaAnterior(1)">
            <i class="flaticon-up-arrow" style="color: #808080 !important"></i>
          </div>
          <span class="text-title" style="margin: 0"
            >{{ typeof this.id === "undefined" ? "Cadastro" : "Edição" }} do
            Sistema</span
          >
          <b-button variant="success" @click="cadastrar">
            {{
              typeof this.id === "undefined" ? "SALVAR" : "ATUALIZAR" 
            }}</b-button
          >
        </div> -->
        <div class="container">
          <br v-if="!cadastro" />
          <h2 class="text-center mt-3">
            {{ cadastro ? "Cadastrar" : "Editar" }} Sistema
          </h2>
          <br />
          <div class="row mt-4">
            <div class="col-6">
              <span class="text-title">Nome do sistema</span>
              <b-form-input
                class="mt-3"
                :state="validateState('sli_titulo')"
                type="text"
                v-model="$v.sistema.sli_titulo.$model"
                aria-describedby="input-sli-titulo-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-sli-titulo-feedback"
                >Você precisa preencher com o nome!</b-form-invalid-feedback
              >
            </div>
            <div class="col-6 col-md-6 mt-3 mt-sm-0">
              <span class="text-title d-block">Agente</span>
              <Dropdown
                class="mt-3 w-100"
                v-model="dropdownLicenciada.selecionada"
                :filter="dropdownLicenciada.opcoes.length > 10 ? true : false"
                :options="dropdownLicenciada.opcoes"
                optionLabel="lic_descricao"
                placeholder="Escolha um agente"
              />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 col-md-6">
              <span class="text-title d-block">Sistema</span>
              <div class="d-flex align-items-end">
                <Dropdown
                  class="mt-3 w-100"
                  @change="atualizarBancos"
                  v-model="dropdownSistema.selecionada"
                  :filter="dropdownSistema.opcoes.length > 10 ? true : false"
                  :options="dropdownSistema.opcoes"
                  optionLabel="sis_nome"
                  placeholder="Escolha o sistema"
                />

                <b-button
                  v-if="dropdownSistema.selecionada.sistema_id == 29"
                  variant="outline-success"
                  class="ml-2"
                  style="height: 35px; width: 45px"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Abrir modelo de tabela"
                  href="https://docs.google.com/spreadsheets/d/1OpK8qllpq3s7jPlb0vU3s4Prn7DzXVvS_y-aCV65h60/edit?usp=sharing"
                  target="_blank"
                >
                  <b-icon icon="table" aria-label="Help" class="h5"></b-icon>
                </b-button>
              </div>
              <b-form-invalid-feedback id="input-sistema-feedback"
                >Campo obrigatório.</b-form-invalid-feedback
              >
              <b-alert
                :show="dropdownSistema.selecionada.sistema_id == 29"
                variant="warning"
                class="mt-2"
                >O arquivo deve ser público ou compartilhado com o e-mail:
                <br />
                suporte@wellon.digital</b-alert
              >
            </div>

            <div class="col-12 col-md-6">
              <span class="text-title d-block">Banco</span>
              <Dropdown
                class="mt-3 w-100"
                v-model="dropdownBancoDados.selecionada"
                :filter="dropdownBancoDados.opcoes.length > 10 ? true : false"
                :options="dropdownBancoDados.opcoes"
                optionLabel="bat_nome"
                placeholder="Escolha o banco"
                :disabled="dropdownBancoDados.desativado"
              />
              <b-form-invalid-feedback id="input-sistema-feedback"
                >Campo obrigatório.</b-form-invalid-feedback
              >
            </div>
          </div>

          <template
            v-if="[50, 51].includes(dropdownSistema.selecionada.sistema_id)"
          >
            <b-row class="mt-6 mb-10">
              <b-col
                cols="6"
                v-if="
                  !oAuthUserInfo && dropdownSistema.selecionada.sistema_id == 50
                "
              >
                <GoogleLoginButton
                  @oauth-data="setOAuthData"
                  class="w-100 mb-2"
                  block
                />
                <span class="font-weight-light"
                  >Para continuar, faça login com sua conta do Google e conceda
                  as permissões para acesso aos recursos do seu
                  calendário.</span
                >
              </b-col>
              <b-col
                cols="6"
                v-else-if="
                  !oAuthUserInfo && dropdownSistema.selecionada.sistema_id == 51
                "
              >
                <MicrosoftLoginButton
                  @oauth-data="setOAuthData"
                  class="w-100 mb-2"
                  block
                />
                <span class="font-weight-light"
                  >Para continuar, faça login com sua conta da Microsoft e
                  conceda as permissões para acesso aos recursos do seu
                  calendário.</span
                >
              </b-col>
              <b-col v-else>
                <div class="d-flex flex-column flex-center">
                  <div class="symbol symbol-120 symbol-success overflow-hidden">
                    <img
                      class="h-75 align-self-end"
                      alt
                      :src="
                        oAuthUserInfo.avatar
                          ? oAuthUserInfo.avatar
                          : '/icon/user.png'
                      "
                    />
                  </div>

                  <div
                    class="card-title font-weight-bolder text-dark-75 text-hover-primary font-size-h4 m-0 pt-7 pb-1"
                  >
                    {{ oAuthUserInfo.email }}
                  </div>
                  <div class="font-weight-bold text-dark-50 font-size-md pb-6">
                    {{ oAuthUserInfo.name }}
                  </div>

                  <button
                    type="button"
                    class="btn btn-link-danger font-size-sm py-3 px-14"
                    @click.prevent="logoutOAathUser"
                  >
                    Deseja usar outra conta? Clique aqui para desconectar!
                  </button>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <div class="d-flex row mt-4">
              <div class="col-6 mt-4">
                <div class="d-flex w-100 justify-content-between">
                  <b-form-checkbox
                    class="mt-2"
                    v-model="sistema.sli_driver_odbc"
                    :value="true"
                    >ODBC
                    <b-icon-info-circle
                      class="ml-2"
                      id="info-odbc"
                      v-b-tooltip.hover
                    />
                    <b-tooltip
                      target="info-odbc"
                      variant="info"
                      placement="right"
                    >
                      Caso seu sistema utilize um driver ODBC, como uma
                      interface entre o sistema e o banco de dados, deixe essa
                      opção marcada.
                    </b-tooltip></b-form-checkbox
                  >
                  <b-form-checkbox
                    class="mt-2"
                    v-model="sistema.sli_modo_debug"
                    :value="true"
                    >Depuração
                    <b-icon-info-circle class="ml-2" id="info-depuracao" />
                    <b-tooltip
                      target="info-depuracao"
                      variant="info"
                      placement="right"
                      style="opacity: none"
                    >
                      No modo de depuração, o agente salva informações mais
                      detalhadas para permitir identificar erros que podem
                      acontecer durante a execução.
                    </b-tooltip></b-form-checkbox
                  >
                </div>
              </div>
              <div class="col-6 mt-4">
                <b-form-checkbox
                  class="mt-2"
                  v-model="sistema.sli_agente_virtual"
                  :value="true"
                  >Licença Virtual<b-icon-info-circle
                    class="ml-2"
                    id="info-virtual"
                  />
                  <b-tooltip
                    target="info-virtual"
                    variant="info"
                    placement="right"
                    style="opacity: none"
                  >
                    Ative essa opção quando não for necessário um agente físico.
                  </b-tooltip></b-form-checkbox
                >
              </div>
            </div>

            <div class="row mt-4">
              <div
                :class="{
                  'col-12': true,
                  'col-md-6': true,
                  'col-md-12': exibirStringConexao()
                }"
              >
                <span class="text-title">{{
                  exibirStringConexao() ? "String de conexão" : "Nome do banco"
                }}</span>
                <a
                  v-if="(eORA() && !sistema.sli_driver_odbc) || eAPI()"
                  class="ml-3"
                  style="font-size: 0.75rem; cursor: pointer"
                  @click="stringConexao = !stringConexao"
                  >{{
                    !exibirStringConexao()
                      ? "String de conexão"
                      : "Dados de conexão"
                  }}</a
                >
                <b-form-input
                  v-if="!exibirStringConexao()"
                  class="mt-3"
                  type="text"
                  :state="validateState('sli_db_database')"
                  v-model="$v.sistema.sli_db_database.$model"
                  aria-describedby="input-sli-db-database-feedback"
                ></b-form-input>
                <b-form-input
                  v-else
                  class="mt-3"
                  type="text"
                  :state="validateState('sli_db_dsn')"
                  v-model="$v.sistema.sli_db_dsn.$model"
                  aria-describedby="input-sli-db-database-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-sli-db-database-feedback"
                  >Digite o nome da base de dados!</b-form-invalid-feedback
                >
              </div>
              <transition
                enter-active-class="animate__animated animate__zoomIn"
              >
                <div class="col-12 col-md-6" v-if="!exibirStringConexao()">
                  <div class="row mt-3 mt-md-0">
                    <div
                      :class="{
                        'col-8': mostrarPorta,
                        'col-12': !mostrarPorta
                      }"
                    >
                      <div class="mr-1">
                        <span class="text-title">Endereço IP</span>
                        <b-form-input
                          class="licen-entrada-endereco mt-3 flex-fill"
                          type="text"
                          :state="validateState('sli_db_dsn')"
                          v-model="$v.sistema.sli_db_dsn.$model"
                          aria-describedby="input-sli-db-dsn-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-sli-db-dsn-feedback"
                          >Digite o endereço do
                          servidor!</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                    <transition
                      enter-active-class="animate__animated animate__zoomIn"
                    >
                      <div class="col-4" v-if="mostrarPorta">
                        <span class="text-title">Porta</span>
                        <b-input
                          class="entrada-porta mt-3 flex-fill"
                          type="number"
                          v-model="sistema.sli_db_port"
                        />
                      </div>
                    </transition>
                  </div>
                </div>
              </transition>
              <div class="col-12 col-md-6 mt-4">
                <span class="text-title">Usuário</span>
                <b-form-input
                  class="mt-3"
                  type="text"
                  v-model="$v.sistema.sli_db_usuario.$model"
                  aria-describedby="input-sli-db-usuario-feedback"
                  autocomplete="off"
                ></b-form-input>
                <b-form-invalid-feedback id="input-sli-db-usuario-feedback"
                  >Digite o usuário do banco!</b-form-invalid-feedback
                >
              </div>
              <div class="col-12 col-md-6 mt-4">
                <span class="text-title">Senha</span>
                <div style="display: flex; align-items: center" class="mt-3">
                  <b-form-input
                    :type="esconderSenha ? 'password' : 'text'"
                    class="entrada-senha"
                    v-model="sistema.sli_db_senha"
                    autocomplete="off"
                  />
                  <span
                    v-if="!esconderSenha"
                    class="display-eye fa fa-eye-slash"
                    @click="esconderSenha = true"
                  ></span>
                  <span
                    v-if="esconderSenha"
                    class="display-eye fa fa-eye"
                    @click="esconderSenha = false"
                  ></span>
                </div>
              </div>
              <div class="col-6 mt-4" v-if="sistema.sli_agente_virtual">
                <span class="text-title">API Token</span>
                <b-form-input
                  class="mt-3"
                  type="text"
                  :state="validateState('sli_db_usuario')"
                  v-model="sistema.sli_api_token_clinica"
                  aria-describedby="input-sli-db-usuario-feedback"
                  autocomplete="off"
                ></b-form-input>
              </div>
            </div>
            <div class="row">
              <div class="col mt-5">
                <div
                  class="w-100 detalhes-collapse pb-5 align-items-center overflow-hidden d-flex justify-content-between"
                  v-b-toggle="'cardCollapseRegister'"
                  @click="isRegisterCollapsed = !isRegisterCollapsed"
                  style="padding: 16px 0px 16px 0px"
                >
                  <div class="col-12 col-sm-6 col-xl-9 align-items-center">
                    <span class> Configurações Adicionais</span>
                  </div>
                  <div class="col-12 col-sm-4 col-xl-2 d-flex flex-row-reverse">
                    <!-- style="display: flex; justify-content: end" -->
                    <a>
                      <i
                        v-if="isRegisterCollapsed"
                        class="ki ki-arrow-down icon-nm ml-2"
                        style="color: black"
                      ></i>
                      <i
                        v-else
                        class="ki ki-arrow-up icon-nm ml-2"
                        style="color: black"
                      ></i>
                    </a>
                  </div>
                </div>
                <b-collapse
                  id="cardCollapseRegister"
                  class="w-100"
                  style="border-top: 1px solid #eee"
                >
                  <div class="w-100">
                    <VueJSONEditor
                      ref="jsoneditor"
                      :content="content"
                      :onChange="onChange"
                    />
                  </div>
                </b-collapse>
              </div>
            </div>
          </template>
          <b-button variant="success" @click="cadastrar" class="w-100 mt-5">
            {{ this.cadastro ? "SALVAR" : "ATUALIZAR" }}</b-button
          >
        </div>
      </b-form>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Dropdown from "primevue/dropdown";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import API_LINKS from "../api.links";
import Toast from "primevue/toast";
import VueJSONEditor from "./components/VueJSONEditor";
import GoogleLoginButton from "@/view/components/GoogleLoginButton.vue";
import MicrosoftLoginButton from "@/view/components/MicrosoftLoginButton.vue";
import Swal from "sweetalert2";

export default {
  name: "CadastroLicenciada",
  components: {
    Dropdown,
    Toast,
    VueJSONEditor,
    GoogleLoginButton,
    MicrosoftLoginButton
  },
  mixins: [validationMixin],
  props: {
    cadastro: {
      default: false
    },
    licenciadaSelecionadaId: {
      default: null
    }
  },
  data() {
    return {
      // A - Ativo; I - Inativo; S - Suspenso
      id: this.$route.params.id,
      mostrarModal: true,
      sistema: {
        banco_id: "",
        sistema_id: "",
        licenciada_id: "",
        sli_status: "A",
        sli_titulo: "",
        sli_db_usuario: "",
        sli_db_senha: "",
        sli_db_database: "",
        sli_db_dsn: "",
        sli_db_port: "",
        sli_configuracoes_adicionais: {},
        checked: []
      },
      isRegisterCollapsed: true,
      sistemaCopia: {},
      esconderSenha: true,
      dropdownLicenciada: {
        selecionada: "",
        opcoes: []
      },
      dropdownSistema: {
        selecionada: "",
        opcoes: []
      },
      dropdownBancoDados: {
        selecionada: { banco_id: "ORA" },
        opcoes: [],
        desativado: true
      },
      stringConexao: false,
      showEditor: true,
      readOnly: false,
      content: {
        json: {},
        text: undefined
      },
      oAuthUserInfo: null
    };
  },
  methods: {
    redirecionarParaCadastrarAgente() {
      this.$emit("redirecionarParaCadastrarAgente");
    },
    exibirStringConexao() {
      let r =
        (this.stringConexao && this.eORA()) ||
        this.sistema.sli_driver_odbc ||
        (this.stringConexao && this.eAPI());
      return r;
    },

    async mostrarModalSemLicenciada() {
      setTimeout(() => {
        this.$modal.show("modal-sem-licenciada");
      }, 1000);
    },
    async cadastrar() {
      if ([50, 51].includes(this.dropdownSistema.selecionada.sistema_id)) {
        if (!this.oAuthUserInfo?.token || !this.oAuthUserInfo?.baseUrl) {
          Swal.fire(
            "Aviso:",
            "Você precisa realizar login em sua conta e conceder as permissões para acesso calendário.",
            "warning"
          );

          return;
        } else {
          this.sistema.sli_db_dsn = this.oAuthUserInfo.baseUrl;
          this.sistema.sli_api_token_clinica = this.oAuthUserInfo.token;
        }
      }

      this.$v.sistema.$touch();
      if (this.$v.sistema.$anyError) {
        return;
      }
      this.sistema.licenciada_id =
        this.dropdownLicenciada.selecionada.licenciada_id;
      this.sistema.sistema_id = this.dropdownSistema.selecionada.sistema_id;
      this.sistema.banco_id =
        this.dropdownBancoDados.selecionada.banco_id.trim();
      Object.keys(this.sistema).forEach(
        (key) => this.sistema[key] == null && delete this.sistema[key]
      );

      //Verifica se o usuário escolheu ORACLE sem String de Conexão, caso sim, é montado a string.
      if (this.eORA() && !this.stringConexao) {
        this.sistema.sli_db_dsn =
          this.sistema.sli_db_dsn.trim() +
          (this.sistema.sli_db_port.length > 0
            ? `:${this.sistema.sli_db_port.trim()}`
            : "") +
          "/" +
          this.sistema.sli_db_database.trim();
        this.sistema.sli_db_database = "";
        this.sistema.sli_db_port = "";
      }

      if (!this.temMudancas()) {
        this.$emit("redirectSemMudancas");
        return;
      }

      let response = await ApiService.post(
        API_LINKS.sistemaLicenciada +
          (!this.cadastro ? "/" + this.licenciadaSelecionadaId : ""),
        { sistema: this.sistema }
      );
      this.$emit("cadastrarEditarLicenciada");
      if (response.data["erro"] != "undefined") {
        let msg_toast = {
          severity: "success",
          summary: "Sistema " + (this.eEdicao() ? "atualizado" : "cadastrado"),
          detail: "Você já pode usar o sistema nas suas campanhas!",
          life: 5000
        };
        this.atualizarCredenciaisAgente(this.sistema.licenciada_id);
        // this.paginaAnterior(1, false, msg_toast);
      } else {
        this.$toast.add({
          severity: "erro",
          summary: "Erro em atualizar suas credenciais",
          detail:
            "Aconteceu um erro durante a tentativa de conexão com o agente! Tente novamente mais tarde.",
          life: 7000
        });
        this.atualizarCredenciaisAgente(this.sistema.licenciada_id);
      }
    },
    async atualizarCredenciaisAgente(licenciada_id) {
      try {
        let response = await ApiService.get(
          API_LINKS.forcarAtualizarCredenciais,
          licenciada_id,
          false
        );
        this.$toast.add({
          severity: "success",
          summary: "Suas credenciais foram atualizadas!",
          detail:
            "Atualizamos as informações de conexão contidas no agente! Isso pode demorar um pouco para surtir efeito.",
          life: 7000
        });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Erro em atualizar suas credenciais",
          detail:
            "Aconteceu um erro durante a tentativa de conexão com o agente! Tente novamente mais tarde.",
          life: 7000
        });
      }
    },
    paginaAnterior(
      navSelecionada,
      abrirCadastroAgente = false,
      msg_toast = null
    ) {
      this.$router.push({
        name: "automacao",
        params: {
          navSelecionada,
          toast: msg_toast,
          cadastrarAgente: abrirCadastroAgente
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.sistema[name];
      return $dirty ? !$error : null;
    },
    limparCampos() {
      this.sistemas = {};
    },
    atualizarBancos() {
      const init = async () => {
        const response = await ApiService.get(
          API_LINKS.banco +
            "?filtro[sistema_id]=" +
            this.dropdownSistema.selecionada.sistema_id
        );
        let bancos = response.data.bancos;
        bancos.forEach((item) => {
          item.banco_id = item.banco_id.trim();
        });

        this.dropdownBancoDados.opcoes = bancos;
        // this.dropdownBancoDados.selecionada =
        //   bancos.length == 1 ? bancos[0] : {};
        this.dropdownBancoDados.selecionada = bancos[0];
        this.dropdownBancoDados.desativado = false;
      };
      init();
    },
    mostrarPorta() {
      return (
        this.dropdownBancoDados.selecionada.banco_id === "MYS" ||
        this.dropdownBancoDados.selecionada.banco_id === "FIR"
      );
    },
    eORA() {
      return this.dropdownBancoDados.selecionada.banco_id.trim() === "ORA";
    },
    eAPI() {
      return this.dropdownBancoDados.selecionada.banco_id.trim() === "API_E";
    },
    eEdicao() {
      return !!this.licenciadaSelecionadaId;
    },
    confirmacaoSaida(evt) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(
          "Existe mudanças que ainda não foram salva. Você tem certeza que deseja sair?",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          // An error occurred
        });
    },
    temMudancas() {
      return this.sistemaCopia != JSON.stringify(this.sistema);
    },
    removeAtrVazio(obj) {
      Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
    },
    onChange(content) {
      this.content = content;
      if (typeof content.json !== "undefined") {
        this.sistema.sli_configuracoes_adicionais = JSON.stringify(
          content.json
        );
      } else if (typeof content.text !== "undefined") {
        this.sistema.sli_configuracoes_adicionais = content.text;
      } else {
        this.sistema.sli_configuracoes_adicionais = JSON.stringify(content);
      }
    },
    setOAuthData(user) {
      if (!user?.email || !user?.token || !user?.baseUrl) {
        Swal.fire(
          "Erro:",
          "Não foi possível obter os dados do usuário, tente novamente!",
          "error"
        );
      } else {
        this.oAuthUserInfo = { ...user };
      }
    },
    logoutOAathUser() {
      Swal.fire({
        title: `Deseja realmente desconectar a conta ${this.oAuthUserInfo.email}?`,
        showCancelButton: true,
        confirmButtonText: "Desconectar!",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.oAuthUserInfo = null;
        }
      });
    }
  },
  validations: {
    sistema: {
      sli_titulo: {
        required
      },
      sli_db_usuario: {},
      sli_db_database: {},
      sli_db_dsn: {},
      sli_db_port: {}
    },
    age: {
      between: between(20, 30)
    }
  },
  mounted() {
    const inicializar = async () => {
      // Carregando as licenças/agentes da API
      let responseLicenciada = await ApiService.get(API_LINKS.licenca);
      let licenciadas = Object.values(responseLicenciada.data.licenciadas);
      licenciadas.forEach((element) => {
        element.lic_descricao =
          element.lic_descricao == null ? "S/N" : element.lic_descricao;
      });
      this.dropdownLicenciada.opcoes = licenciadas;
      if (licenciadas.length == 0) {
        this.mostrarModalSemLicenciada();
      }

      // Carregando os sistema bancos da API
      let responseSistema = await ApiService.get(API_LINKS.sistema);
      let sistema = responseSistema.data.sistemas;
      this.dropdownSistema.opcoes = sistema;

      // Caso seja edição de Sistema Licenciada
      if (this.licenciadaSelecionadaId) {
        this.stringConexao = true;
        let response = await ApiService.get(
          API_LINKS.sistemaLicenciada,
          this.licenciadaSelecionadaId
        );
        this.sistema = response.data.sistema;
        this.content.json = JSON.parse(
          response.data.sistema.sli_configuracoes_adicionais
        );

        this.$refs.jsoneditor.atualizar();

        this.sistemaCopia = JSON.stringify(this.sistema);
        // Preenche licencas,sistema e o banco do sistema licenciada
        this.dropdownLicenciada.selecionada =
          this.dropdownLicenciada.opcoes.find(
            (item) => item.licenciada_id == this.sistema.licenciada_id
          );
        this.dropdownSistema.selecionada = this.dropdownSistema.opcoes.find(
          (item) => item.sistema_id == this.sistema.sistema_id
        );

        response = await ApiService.get(
          API_LINKS.banco +
            "?filtro[sistema_id]=" +
            this.dropdownSistema.selecionada.sistema_id
        );
        this.dropdownBancoDados.opcoes = response.data.bancos;
        this.dropdownBancoDados.desativado = false;

        this.dropdownBancoDados.selecionada =
          this.dropdownBancoDados.opcoes.find(
            (item) => item.banco_id.trim() == this.sistema.banco_id
          );
      }
    };
    inicializar();
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&display=swap");

.text-title {
  font-family: "Inter", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  color: #212226;
  font-weight: 300;
}

.licen-entrada-endereco {
  height: 40px;
  border: 1px solid #e4e6f0;
}

.detalhes-collapse {
  outline: 1px solid #eee;
  padding: 0;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 0.42rem;
}

.entrada-senha {
  width: 100%;
  height: 40px;
  border: 1px solid #e4e6ef;
  border-radius: 5px 0px 0px 5px;
  border-right: none;
  /* outline: none; */
  padding: 8px 13px;
}

.entrada-porta {
  height: 40px;
  border: 1px solid #e4e6ef;
  outline: none;
  padding: 8px 13px;
  border-radius: 5px;
}

.display-eye {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #565656;
  width: 40px;
  height: 40px;
  border: 1px solid #e4e6ef;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
}

.licen-botao-voltar {
  color: #808080;
  cursor: pointer;
  transition: all 0.1s linear;
}

.licen-botao-voltar:hover {
  color: #000;
  transform: scale(1.1);
}

.linha {
  margin-bottom: 18px;
}

.licen-container {
  width: 100%;
  min-height: 550px;
  padding-bottom: 30px;
  background-color: #fff;
  border-radius: 10px;
}

.licen-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e6ef;
  padding: 0px 15px;
  height: 60px;
}

.licen-container-corpo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
  height: 480px;
}

.my-editor {
  width: 700px;
  height: 400px;
  display: flex;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.back-arrow-squared {
  border: 1px solid #808080;
  border-radius: 4px;
  transform: rotate(-90deg);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
